import { UserService } from '@ami/angular/core/auth';
import { ErrorHandlingService } from '@ami/angular/core/error-handling';
import { EventTypes, ToastService } from '@ami/angular/core/toasts';
import { CommonModule } from '@angular/common';
import { Component, enableProdMode } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ValdemortConfig, ValdemortModule } from 'ngx-valdemort';

@Component({
  standalone: true,
  imports: [RouterModule, ValdemortModule, CommonModule],
  selector: 'ami-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'customer';

  constructor(
    private errorHandlingService: ErrorHandlingService,
    private toastService: ToastService,
    private userService: UserService,
    private router: Router,
    private valdemortConfig: ValdemortConfig
  ) {
    this.valdemortConfig.errorsClasses = 'invalid-feedback';
  }

  async ngOnInit() {
    this.errorHandlingService.errorEvents$.subscribe(error => {
      this.toastService.queueToast(error.message, EventTypes.Error);
      if (error.error.status == 401) {
        this.userService.logout();
        this.router.navigateByUrl("/public/login");
      }
    });

    if (process.env['NODE_ENV'] === 'production') {
      enableProdMode();
    }
  }
}
