import { Injectable } from "@angular/core";

export const nxApiBaseUrl: string = process.env['NX_API_BASE_URL']!;
export const showTestBanner: boolean = JSON.parse(process.env['NX_APP_SHOW_TEST_BANNER']!);

@Injectable({
  providedIn: 'root'
})
export class AppSettings {
  readonly showTestBanner: boolean = showTestBanner;
  readonly nxApiBaseUrl = nxApiBaseUrl;
}
