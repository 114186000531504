import { Route } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';

export const appRoutes: Route[] = [
  {
    path: 'inventory',
    loadChildren: () =>
      import('@ami/customer/inventory/v1').then((mod) => mod.v1Routes),
  },
  {
    path: 'documents',
    loadChildren: () =>
      import('@ami/customer/documents/v1').then((mod) => mod.v1Routes),
  },
  {
    path: 'approvals',
    loadChildren: () =>
      import('@ami/customer/approvals/v1').then((mod) => mod.v1Routes),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
