import { localSessionOAuthStorageFactory } from '@ami/angular/core/auth';
import { ErrorHandling } from '@ami/angular/core/error-handling';
import { HttpInterceptorService } from '@ami/angular/core/http-interception'
import { provideHttpBaseUrlReplacement } from '@ami/angular/core/http';
import { AMI_SITE_TYPE, AmiSiteType } from '@ami/angular/core/ui';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding, withEnabledBlockingInitialNavigation } from '@angular/router';
import { OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { appRoutes } from './app.routes';
import { nxApiBaseUrl } from './app.settings';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      NgxWebstorageModule.forRoot({ prefix: 'ami_dealer', separator: '_', caseSensitive: true }),
    ),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
    provideHttpBaseUrlReplacement({
      aliases: [{
        alias: 'api',
        baseUrl: nxApiBaseUrl,
      }]
    }),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: [`${nxApiBaseUrl}/api`, '@api'],
        sendAccessToken: true,
      },
    }),
    { provide: OAuthStorage, useFactory: localSessionOAuthStorageFactory },
    {
      provide: ErrorHandler,
      useClass: ErrorHandling,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    // {
    //   provide: AuthConfig,
    //   useValue: {
    //     issuer: `${nxApiBaseUrl}/`,
    //     strictDiscoveryDocumentValidation: false,
    //     oidc: false,
    //     scope: 'openid profile offline_access dealer',
    //     showDebugInformation: true
    //   }
    // },
    // provideFeatureFlags(),
    { provide: AMI_SITE_TYPE, useValue: AmiSiteType.Dealer }
  ],
};
